<template>
  <div>
    <div ref="editor" class="editor" v-html="content" />
  </div>
</template>

<script>
import E from 'wangeditor'
// import { getToken } from '@/utils/auth';
import { getStore } from '@/utils/store'
export default {
  name: 'Editor',
  props: {
    content: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    }
  },
  data () {
    return {
      editor: null,
      video: '',
      editorContent: ''
    }
  },
  mounted () {
    this.editor = new E(this.$refs.editor)
    // 配置上传图片
    this.editor.config.uploadImgServer = process.env.VUE_APP_BASE_API + 'admin/file/save'
    this.editor.config.uploadFileName = 'file'
    this.editor.config.uploadImgTimeout = 30 * 1000
    this.editor.config.uploadImgHeaders = {
      'Auth-Key': getStore('authKey') ? getStore('authKey') : '',
      'Session-Id': getStore('sessionId') ? getStore('sessionId') : '',
      'Platform': 'pc'
    }
    this.editor.config.uploadImgHooks = {
      customInsert: (insertImg, { code, data }) => {
        if (code === 200) {
          const url = data.file_path
          insertImg(url)
        } else {
          this.$message.info('富文本编辑器上传图片错误！')
        }
      }
    }
    // 定义placeholder
    this.editor.config.placeholder = this.placeholder
    // 定义内容改变时回调
    this.editor.config.onchange = (newHtml) => {
      this.editorContent = newHtml
      this.getContent()
    }
    this.editor.config.onchangeTimeout = 500
    // 自定义菜单配置
    this.editor.config.excludeMenus = [
      'todo',
      'code'
    ]
    // 取消自动聚焦
    this.editor.config.focus = false
  },
  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  },
  methods: {
    initContent () {
      // console.log('子组件获取', this.content, 'editor实例', this.editor);
      this.editor.create()
    },
    getContent () {
      // console.log('富文本内容', this.editorContent);
      this.$emit('getContent', this.editorContent)
    }
  }
}
</script>

<style scoped lang="scss">
  .editor {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    ::v-deep.w-e-droplist {
      z-index: 8!important;
    }
    ::v-deep.w-e-toolbar {
      z-index: 8!important;
    }
    ::v-deep.w-e-text-container {
      z-index: 5!important;
      height: 60vh!important;
    }
     ::v-deep.w-e-text{
    height: 60vh!important;
  }
  }
 
</style>
