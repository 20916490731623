<template>
  <div class="centre">
    <!-- 图文详情富文本 -->
    <editor ref="editor" :content="remark" placeholder="请输入详情" @getContent="getContent" />
    <el-button size="small" class="sbmButton" type="primary" @click="putImgText">保存</el-button>
  </div>
</template>

<script>
import editor from '@/components/RichEditor/index'
import { setMiniIndex,miniIndex } from '@/api/sys';
export default {
  name: 'allocation',
  components: {
    editor
  },
  data () {
    return {
      remark: '', 
      html: '', 
    }
  },

  mounted () {
    this.clickImgText()
  },
  methods: {
    getContent (html) {
      this.html = html
    },
    // 图文详情
    clickImgText () {
      miniIndex().then(res => {
        this.remark = res.data.content;
        this.$nextTick(() => {
          this.$refs.editor.initContent();
        });
      })
      
    },
    // 提示
    putImgText () {
      if(!this.html){
         this.$message({
          message: '请添加内容！',
          type: 'warning'
        });
      }else{
         this.$confirm(`确定小程序首页为该内容吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log('452335')
          this.confirm()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }
    },
    // 提交
    confirm(){
      setMiniIndex({content:this.html}).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.centre{
  width: 92%;
  margin: 40px auto 10px;
}
.sbmButton{
 margin: 0 49%;
}
</style>
